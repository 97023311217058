.solution {
  padding: 4rem 6rem;
}

.solution h1 {
  font-size: 3rem;
}
.solution h1 + p {
  font-size: 1.1rem;
  text-align: left;
  
}

.first-sol,
.first-sol-reverse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.first-sol-reverse {
  flex-direction: row-reverse;
}
.sol-text h2 a{
  color: #1e3f66;
}
.sol-text h2 a:hover{
  color: #ff4c00;
}
.sol-text{
  padding: 1rem;
}
.image {
  padding: 1rem;
}
.sol-text {
  flex: 1;.solution {
    padding: 4rem 6rem;
  }
  
  .solution h1 {
    font-size: 3rem;
  }
  
  .first-sol,
  .first-sol-reverse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
  }
  
  .first-sol-reverse {
    flex-direction: row-reverse;
  }
  
  .sol-text {
    flex: 1;
    text-align: start;
    font-size: 1.1rem;
    padding: 1rem;
  }
  
  .sol-text h2 {
    padding-bottom: 1rem;
  }
  
  .image {
    position: relative;
    width: 20%; 
    display: flex;
    justify-content: center; 
    padding: 1rem;
  }
  
  .image img {
    
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 350px; 
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
  }
  
  @media screen and (max-width: 850px) {
    .solution {
      padding: 4rem 2rem;
    }
  
    .solution h1 {
      font-size: 2.5rem;
    }
  
    .first-sol,
    .first-sol-reverse {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 3rem;
    }
  
    .sol-text {
      width: 100%;
      text-align: left;
      font-size: 1rem;
      padding: 1rem;
    }
  
    .sol-text h2 {
      padding-bottom: 0.5rem;
    }
  
    .image {
      width: 100%;
      text-align: center;
      padding: 1rem;
    }
  
    .image img {
      width: 100%;
      max-width: 100%;
      height: auto;
      max-height: 250px;
      max-width: 250px;
      object-fit: cover;
      border-radius: 6px;
      box-shadow: -1px 1px 10px -2px rgba(0, 0, 0, 0.19);
    }
  }
  
  text-align: start;
  font-size: 1.1rem;
}

.sol-text h2 {
  padding-bottom: 1rem;
}

.image {
  position: relative;
  width: 20%; 
  display: flex;
  justify-content: center; 
}

.image img {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 350px; 
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

@media screen and (max-width: 850px) {
  .solution {
    padding: 4rem 2rem;
  }

  .solution h1 {
    font-size: 2.5rem;
  }

  .first-sol,
  .first-sol-reverse {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;
  }

  .sol-text {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding: 1rem;
  }

  .sol-text h2 {
    padding-bottom: 0.5rem;
  }

  .image {
    width: 100%;
    text-align: center;
    padding: 1rem;
  }

  .image img {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 250px;
    max-width: 250px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 10px -2px rgba(0, 0, 0, 0.19);
  }
}
