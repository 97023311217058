.footer {
  padding: 1rem 4rem;
  background: #1e3e66;
  color: #fff;
}

.top a:hover {
  color: #ff4c00;
}

.top a {
  color: #fff;
  text-decoration: none;
}

.top h1 {
  font-size: 2rem;
}

.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.top i {
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
}

.top i:hover {
  color: #ff4c00;
}

.bottom {
  padding-top: 0.6rem;
  text-align: start;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bottom div {
  display: flex;
  flex-direction: column;
}

.bottom h4 {
  font-size: 1.3rem;
  padding: 1rem 0.8rem 0;
}

.bottom a:hover {
  color: #ff4c00;
}

.bottom a {
  text-decoration: none;
  color: #fafafa;
  padding-bottom: 0.4rem;
  font-size: 1rem;
}

.bottom a i {
  margin-right: 10px;
}

.email-input {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  font-size: 15px;
  position: relative;
  outline: none;
}

.subscription {
  text-decoration: none;
  color: #fff;
  background-color: #ff4c00;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  transition: 0.5s;
}

.subscription:hover {
  background-color: #fff;
  color: #ff4c00;
}


@media screen and (max-width: 850px) {
  .footer {
    padding: 2rem;
  }

  .top {
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .top h1 {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .top i {
    margin: 0.5rem;
  }

  .bottom {
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .bottom div {
    margin-bottom: 1rem;
    align-items: flex-start;
  }

  .bottom h4 {
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
  }

  .bottom a {
    font-size: 0.9rem;
    padding-bottom: 0.3rem;
  }

  .email-input {
    width: 100%;
    font-size: 14px;
  }

  .subscription {
    font-size: 15px;
  }
}