.survey-container {
  text-align: center;
  background-color: #f7f7f7;
  padding: 20px;
}

.survey-container h1 {
  font-size: 24px;
  margin: 10px 0;
  color: #1e3f66;
  margin-bottom: 20px;
}
.survey-container a {
  color: #1e3f66; 
  transition: color 0.3s; 
}

.survey-container a:hover {
  color: #fff; 
  text-decoration: underline; 
}
.survey-container p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.survey-container h1:first-child {
  font-size: 36px;
  color: #1e3f66; 
}

.survey-container h1:hover {
  cursor: pointer;
  text-decoration: underline;
}

.survey-container h1:nth-child(n+2) {
  font-size: 28px;
  color: #1e3f66;
  margin: 20px 10px;
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #ff4c00;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.survey-container h1:nth-child(n+2):hover {
  background-color: #1e3f66;
  color: #fff;
}

.numbered-circles {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40vh; 
}
.title-container {
  text-align: center;
  margin-bottom: 20px;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}
.circle {
  text-align: center;
}

.number {
  width: 50px;
  height: 50px;
  background-color: #ff4c00; 
  color: #fff;
  border-radius: 50%;
  font-size: 26px;
  line-height: 40px; 
}

.text {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
} 

.content-container {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #f7f7f7;
}

.first-content{
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ff4c00;
  border-radius: 5px;
  margin-right: 20px;
  text-align: center;
}

.first-content h1 {
  font-size: 24px;
  color: #1e3f66;
  margin-bottom: 10px;
}

.first-content p {
  font-size: 16px;
  color: #666;
}


@media (max-width: 850px) {
  .survey-container h1 {
    font-size: 20px;
  }
 
  .survey-container h1:first-child {
    font-size: 32px;
  }

  .survey-container h1:nth-child(n+2) {
    font-size: 24px;
  }

  .title {
    font-size: 20px; 
    margin-bottom: 50px;
    
  }

  .numbered-circles {
    flex-direction: column; 
    align-items: center; 
  }

  .circle {
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin-bottom: 20px; 
  }

  .number {
    width: 50px;
    height: 50px;
    background-color: #ff4c00;
    color: #fff;
    border-radius: 50%;
    font-size: 26px;
    line-height: 50px; 
  }

  .text {
    margin-top: 10px;
    font-size: 16px;
    color: #333;
  }

  .content-container {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center;
  }

  .content-container > .first-content {
    margin-bottom: 20px; 
  }

  .first-content {
    flex: 1;
    margin-right: 0;
  }

  .first-content h1 {
    font-size: 20px;
  }
}
