  .contact-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.contacts-page-container{
  margin-top: 60px; 
  padding: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="tel"],
select[type="industry"],
textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #1e3e66;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.formss {
    padding-left: 20px;  
    padding-right: 20px; 
    margin-bottom: 40px; 
    margin-top: 40px;    
}

.formss h3 {
    margin-bottom: 20px; 
}

  .background-image-banner {
    background-image: url('../assets/contactform.png'); 
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; 
    margin-right: 5rem;
    margin-left: 5rem;
    margin-top: 2rem;
}
.forms-container {
    
    border: 1px solid #ff4c00; 
    border-radius: 5px;
    padding: 20px; 
    margin-left: 20rem;
    margin-right: 20rem;
}
.contacts-container {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 40rem;
    max-width: 450px;
    padding: 2rem;
    text-align: left;
    
}
  .contacts-container h1 {
    color: #ff4c00;
  }
.form-and-columns-container {
    display: flex;
    justify-content: space-between; 
    width: 100%; 
    max-width: 1000px; 
    margin: 0 auto; 
    margin-top: 3rem;
    margin-bottom: 5rem;
    text-align: left;
  }
  .form-and-columns-container a {
    color: #3478ca;
   
  }
  
  .columns-container {
    flex-basis: calc(50% - 10px); 
  }
  
  .column-box {
    padding: 1rem; 
    background-color: #ffffff; 
    border-radius: 5px; 
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border: 1px solid #ff4c00;
  }

@media screen and (max-width: 767px) {
  .forms-container {
      margin-left: 1rem;
      margin-right: 1rem;
  }
  .background-image-banner {
    background-image: none; 
  }

  .contacts-container {
    margin-right: 0;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-right: 5rem;
    padding-left: 5rem;
    border: 1px solid #ff4c00
}
  .contacts-page-container{
    margin-top: 20px; 
    ;
  }
  .contacts-container > h2 {
    font-size: 20px;
    
  }

  .contacts-container > h1 {
    font-size: 30px;
  }

  .contacts-container > p {
    font-size: 14px;
  }
  .form-and-columns-container {
    flex-direction: column; 
    align-items: center;
    
  }

 .columns-container {
    width: 100%; 
    margin-bottom: 20px;
    
  }
 

}