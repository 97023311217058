.NavbarItems {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
    width: 95%;
    height: 80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    text-decoration: none;
  }
  .navbar-logo h1{
    color: #1e3f66;
    font-size: 2rem;
    cursor: pointer;
    display: inline;
    margin-right: 20px;
    font-weight: bolder;
 }
 
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
  }
.dropdown {
  position: relative;
}

.dropdown .sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%
}

.dropdown:hover .sub-menu {
  display: block;
}

.sub-menu-item {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  width: 100%;
  text-align: left;
  list-style: none;
}

.sub-menu-item:hover {
  background-color: #f0f0f0;
  color:#ff4c00
}
  
  .nav-links {
    text-decoration: none;
    color: #1e3f66;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
  }
  
  .nav-links i {
    padding-right: 10px;
  }
  
  .nav-links:hover {
    background-color: #ff4c00;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s easi-in-out;
  }
  
  .nav-links-mobile {
    display: none;
  }
  .menu-icons {
    display: none;
  }
  @media screen and (max-width: 850px) {
    .dropdown .sub-menu{
      display: none;
    }
    .NavbarItems {
      z-index: 99;
    }
    .nav-menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background: #fff;
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: -100%;
      opacity: 0;
      align-items: stretch;
      padding: 80px 0 30px 0;
      margin: 0;
      transition: 0.3s ease-in-out;
      border-radius: 13px;
    }
    .nav-menu.active {
      left: 0;
      opacity: 1;
      z-index: -1;
      transition: 0.3s ease-in-out;
    }
    .nav-links {
      display: block;
      width: 100%;
      font-size: 1.2rem;
      padding: 2rem 0;
    }
    .nav-links:hover {
      background: #ff4c00;
      transition: none;
    }
    .menu-icons {
      display: block;
      cursor: pointer;
    }
    .menu-icons i {
      font-size: 1.2rem;
      color: #222;
    }
  }
  