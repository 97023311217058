.about-container {
  margin: 2rem 4rem;
  text-align: center;
  background-color: #f0f0f0;
  justify-content: center;
  text-align: left;
  
}
.about-column{
  flex: 1;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0.5rem;
}
.about-title {
  font-size: 2rem;
  color: #222;
  margin-bottom: 0.8rem;
  
}
.about-subtitle {
  font-size: 1.8rem;
  color: #222;
  margin-bottom: 1rem;
}
.about-paragraph {
  font-size: 1.1rem;
  color: #1e3f66;
  line-height: 1.6;
  margin-bottom: 1rem;
}
.about-image {
  max-width: 50%;
  height: auto;
  margin: 2rem 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 850px) {
  .about-container {
    margin: 4rem 2rem;
  }
  .about-paragraph{
    font-size: 1rem;
  }
}


