   .clients-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
    padding: 0;
  }
  
  .client-item {
    flex: 0 0 calc(15% - 18); 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: transparent;
    border-radius: 8px;
    margin: 0 5px 10px; 
    
  }
  
  .client-item:last-child {
    margin-right: 0;
  }
  
  .client-item img {
    background-color: transparent;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    margin-bottom: 10px;
    filter: grayscale(100%); 
  }
  
  .clients-page {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .clients-heading {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  @media screen and (max-width: 850px) {
    .client-item {
      flex: 0 0 calc(33.33% - 20px); 
    }
  
    .clients-heading {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  